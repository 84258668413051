/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Ui.Modals {
        import Component = Mobilize.System.Aop.Component;
        @Component("~/dist/resources/modals/dialog/ExpiredMessage.html", "~/dist/resources/modals/dialog/MessageBox.css")
        export class ExpiredSession extends Mobilize.Ui.Modal {
            windowKendo: kendo.ui.Window;
            viewModal: kendo.data.ObservableObject;

            constructor() {
                super();
                var self = this;
                this.viewModal = new kendo.data.ObservableObject(
                    {
                        isVisible: false,
                        status: "modal-overlay dialog dialog-close",
                        textValue: "",
                        myclick: self.retry
                    });
                this.onLoad = this.load;
            }

            retry(): void {
                $(window).unbind('beforeunload');
                var url = window.location.href;
                if (url.indexOf('#') > 0) {
                    url = url.substring(0, window.location.href.indexOf('#'));
                    window.location.href = url;
                    window.location.assign(url);
                    location.replace(url);
                }
                else {
                    location.reload(true);
                }
            }

            private load() {
                $(window).unbind('beforeunload');
                kendo.bind("#messageBoxExpired", this.viewModal);
            }

            name(): string {
                return "exp";
            }

            show(args: any) {
                $(window).unbind('beforeunload');
                $(window).unbind('unload');
                this.viewModal.set("isVisible", true);
                this.viewModal.set("textValue", "The application session has timeout! You must reload the application.");
                this.viewModal.set("status", 'modal-overlay dialog dialog-open');
                $("#messageBoxExpired").find(".btnMessageBox")[0].textContent = "OK";
                if (eval("typeof hideBusyLoader === 'function'")){
                    eval("hideBusyLoader()");
                }
            }
        }
    }
}