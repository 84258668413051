/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Entity {
        export class ModelFactory implements Contract.Core.IModelFactory {
            create(model): Mobilize.Contract.Core.IModel {
                return new Model(model.UniqueID, model);
            }
        }
    }
}