/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {

    export class Widget {
        static plugin(widget: any, name: string) {
            widget.fn = widget.prototype;
            widget.fn.options = $.extend(true, {}, widget.fn.options);
            widget.fn.options.name = name;
            kendo.ui.plugin(widget);
        }
    }
}
