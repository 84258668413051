/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        var ui = kendo.ui, Widget = ui.Widget;

        export class BaseUserControl extends BaseWidget {
            options = {
                /** the name is what it will appear as off the kendo namespace(i.e. kendo.ui.UserControl1).
                 *The jQuery plugin would be jQuery.fn.kendoUserControl1.
                 */
                name: "BaseUserControl",
                value: null,
                css: "",
                template: "<div></div>",
                uiinitialized: false
            }
            element: any;

            applyTemplate(value) {
                const that = this;
                const proto = that.constructor.prototype;
                if (!value["$$$parent"]) {
                    const parent = value.parent();
                    if (parent) {
                        value["$$$parent"] = KendoUtils.getBindingExpressionFromModel(value);
                    }
                }

                //add bindings of the visible and enabled
                if (!this.element || this.element[0].id != value.Name) {
                    let idxDataBind: number = this.options.template.indexOf("data-bind"),
                        idx = this.options.template.indexOf('>'),
                        strAddBindsStart: string = "",
                        strAddBindsEnd: string = "",
                        strAddBindContent: string = "";
                    if (idxDataBind > -1 && idxDataBind < idx) {
                        idxDataBind += 9;
                        while (this.options.template[idxDataBind] != '"') {
                            idxDataBind++;
                        }
                        idx = idxDataBind + 1;
                        strAddBindsEnd = ',';
                    } else {
                        strAddBindsStart = ' data-bind="';
                        strAddBindsEnd = '"';
                    }
                    strAddBindContent += strAddBindsStart + ' visible : #= $$$parent #.Visible, ' + 'enabled : #= $$$parent #..Enabled  ' + strAddBindsEnd;
                    this.options.template = this.options.template.substring(0, idx) + strAddBindContent + this.options.template.substring(idx);
                }

                if (!proto._compiledTemplate) {
                    proto._compiledTemplate = kendo.template(proto.options.template);
                }
                this.initStyles();
                this.initClientMethods(value);
                return proto._compiledTemplate(value);
            }

            initStyles() {
                const that = this;
                //ADD CSS RULES DYNAMICALLY
                const addCssRule = (styles, id) => {
                    if (!document.getElementById(id)) {
                        const style = document.createElement("style");
                        const styleMark = document.createElement("script");
                        const styleIdAtt = document.createAttribute("id");
                        styleIdAtt.value = id;
                        style.type = "text/css";
                        if ((style as any).styleSheet) (style as any).styleSheet.cssText = styles; //IE
                        else style.innerHTML = styles; //OTHERS

                        document.getElementsByTagName("head")[0].appendChild(style);
                    }
                };
                addCssRule(that.options.css, `${that.options.name}`);
            }

            initClientMethods(value) {
                const that = value;
            }

            baseUserControlInitUI() {

            }

            initUI() {
                try {
                    let that = this;
                    that.options.uiinitialized = true;
                    //Set Options is defined in Widget
                    (that as any).setOptions(that.options);
                    that = this;
                    const view = that.ViewModel;
                    const parent = (that.ViewModel as any).parent();
                    if (parent) {
                        that.ViewModel["$$$parent"] = KendoUtils.getBindingExpressionFromModel(view);
                        const html = this.applyTemplate(view);
                        const staticChildControls = $(that.element).children();
                        that.element.html(html);
                        that.insertStaticChildControlIntoTemplate(staticChildControls);

                        that.applyRefreshVisible(that.element.element, that.ViewModel);
                        that.applyRefreshEnabled(that.element.element, that.ViewModel);
                    }
                    that.baseUserControlInitUI();
                } catch (exp) {
                    console.error(`BaseUserControl::initUI exception${exp}`);
                }
            }

            applyRefreshVisible(element, viewModel) {
                if (viewModel.Visible) {
                    this.element.show();
                }
                else {
                    this.element.hide();
                }
            }

            applyRefreshEnabled(element, viewModel) {
                if (viewModel.Enabled) {
                    this.element.prop('disabled', true);
                }
                else {
                    this.element.prop('disabled', false);
                }
            }

            private insertStaticChildControlIntoTemplate(staticChildControls:any) : void {
                if (staticChildControls.length > 0) {
                    var selectorToFindAllDivs = $(this.element).find("div");
                    var divCount = selectorToFindAllDivs.length;
                    var lastDivIndex = divCount - 1;
                    $(selectorToFindAllDivs[lastDivIndex]).append(staticChildControls);
                }
            }
        }


        ui.plugin(Widget.extend(new BaseUserControl()));
    }
}