/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Entity {
        export class Model extends Core.Model {
            constructor(uniqueId: string = "", obj: any = null) {
                super(uniqueId, obj);
                this.onModelChange = this.triggerChange;
            }

            updateModel(item: Contract.Core.IModel) {
                this.applyOnProperties(item, (prop) => {
                    if (prop.indexOf("@") === -1 && this[prop] !== item[prop]) {
                        if (!this["@arr"]) {
                            (this as any).set(prop, item[prop]);
                        } else {
                            this[prop] = item[prop];
                        }
                    }
                });
            }

            addReference(property: string, item: Contract.Core.IModel) {
                const observable = this as any;
                if (observable.set) {
                    observable.set(property, item);
                } else {
                    item["parent"] = () => { return observable; }
                    // replace the child reference if it already exists and it's a temporary model
                    let deleteCount = (observable[item.getPos(this.UniqueID)] && observable[item.getPos(this.UniqueID)].IsTempModel) ? 1 : 0;
                    observable.splice(item.getPos(this.UniqueID), deleteCount, item);
                }
            }

            addValue(property: string, value: any) {
                const observable = this as any;
                if (observable.set) {
                    observable.set(property, value);
                }
            }

            private triggerChange() {
                const observable = (this as any);
                if (observable.trigger) {
                    observable.trigger("change");
                }
            }

            private focus() {
                if ($.isNumeric(this.uniqueName())) {
                    var uid = this.UniqueID.split("#");
                    if (uid.length < 2) return;
                    var controlArrayID = "_" + uid[1] + "_" + uid[0];
                    $(`#${controlArrayID}`).focus();
                }
                else {
                    $(`#${this.uniqueName() }`).focus();
                }
            }
        }
    }
}