/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace WebComponents {

        export class FancyTextBox {
           
            textBox: any;
            element: any;
            events = ["change"];
            data :any;

            /**
             * List of all options supported and default values
             */
            options = {
                name: "FancyTextBox",
                autoBind: true
            };

            

            //HTML for the templates that comprise the widget
            templates = {
                textBox:
                    "<div class='textBox'><i class='material-icons'></i><input type='text' required><label>Greeting</label></div>"
            };

            /**
             * Kendo calls this method when a new widget is created
             * @param element
             * @param options
             */
            init(element, options) {
                kendo.ui.Widget.fn.init.call(this, element, options);
                const template = kendo.template(this.templates.textBox);
                this.textBox = $(template(this.options));
                this.element = $(element);
                this.element.append(this.textBox);
                var self = this;
                this.element.find("input[type=text]").change(() => {
                        if (self.data && self.data.__onchange) {
                            self.data.set("Text", self.element.find("input[type=text]").val());
                            self.data.__onchange("Text");
                        }
                    });
            }


            /**
             * MVVM framework calls 'value' when the viewmodel 'value' binding changes
             */
            value(value) {
                
                this.data = value;
                const self = this;
                if (this.data && this.data.bind) {
                    this.data.bind("set",
                        (e) => {
                            if (e.field === "Text") {
                                self.element.find("input[type=text]").val(e.value);
                            }
                        });
                }
                this.element.find("input[type=text]").val(value.Text);
            }


            /**
             * performs any UI updates
             */
            refresh(value) {
                if (this.data) {
                    
                }
            }

        }

        kendo.ui.plugin(kendo.ui.Widget.extend(new FancyTextBox()));
 
    }
}

