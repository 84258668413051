/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Resolvers {
        export class KendoModelResolver extends Mobilize.Core.ModelResolver{

            constructor(inject?: Mobilize.Contract.Application.IInject) {
                super(inject);
                this.PreProcessArray = this.preArray;
                this.PostProcessArray = this.postArray;
            }


            private preArray(model: Contract.Core.IModel) {
                var observable = window.app.models.getModel(model.UniqueID);
                observable["changeEvent"] = observable["_events"]["change"];
                observable["_events"]["change"] = [];
            }

            private postArray(model: Contract.Core.IModel) {
                var observable = window.app.models.getModel(model.UniqueID);
                observable["_events"]["change"] = observable["changeEvent"];
                observable["changeEvent"] = [];
            }

        }
    }
}