/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export class DragAndDropRegister implements Mobilize.Contract.Ui.IReceiver {
        private commands: Mobilize.Contract.Application.IDictionary;

        constructor() {
            this.commands = new Mobilize.Application.Dictionary();
        }

        execute(command: Mobilize.Contract.Ui.ICommand): any {
            if (this.commands[command.name]) {
                this.commands[command.name](command);
            }
        }
        hasCommand(command: Mobilize.Contract.Ui.ICommand): boolean {
            return this.commands.containsKey(command.name);
        }
        registerCommand(name: string, fn: (command: Mobilize.Contract.Ui.ICommand) => void): any {
            this.commands.add(name, fn);
        }

        dragHandler(command: Ui.Command.DragAndDrop) {
            const controlSelector = "#" + command.form + " #" + command.ControlName;
            var draggable = $(controlSelector).data("kendoDraggable");
            if (draggable === (null || undefined)) {
                $(controlSelector).kendoDraggable({
                    hint: function () {
                        return $(controlSelector).clone();
                    }
                });
                draggable = $(controlSelector).data("kendoDraggable");
            }
            
            var event = (window as any).app.mouseDragEvent;
            if (event !== undefined && event !== null) {
                draggable["userEvents"]._start(event);
            }
            else {
                console.log("The mouseDragEvent variable is not set. The dragging event cannot start.");
            }
        }
    }
}