module WebMap.Utils {

    export function IsClassSet(classes: string, cls: string): boolean {
        var idx = classes.indexOf(cls);
        var len = cls.length;
        return (idx == 0 && (classes.length == len || classes[len] == ' ')) || (idx > 0 && classes[idx - 1] == ' ' && ((idx += len) == classes.length || classes[idx] == ' '));
    }

    export function RemoveClass(classes: string, cls: string): string {
        var len = cls.length;
        var idx = classes.indexOf(cls);
        if (idx >= 0) {
            var idx2 = idx + len;
            var before = "", after = "";
            if (idx > 0) {
                if (classes[idx - 1] == ' ')
                    idx--;
                else
                    return classes;
                before = classes.substring(0, idx);
            }
            if (idx2 < classes.length) {
                if (classes[idx2] == ' ')
                    idx2++;
                else
                    return classes;
                after = classes.substring(idx2);
            }
            return (before + ' ' + after).trim();
        }
        return classes;
    }

    export function convertDate(value: any): string {
        var year = value.getFullYear();
        var month = (value.getMonth() + 1 < 10) ? "0" + (value.getMonth() + 1) : (value.getMonth() + 1);
        var date = (value.getDate() < 10) ? "0" + value.getDate() : value.getDate();
        var hours = (value.getHours() < 10) ? "0" + value.getHours() : value.getHours();
        var minutes = (value.getMinutes() < 10) ? "0" + value.getMinutes() : value.getMinutes();
        var seconds = (value.getSeconds() < 10) ? "0" + value.getSeconds() : value.getSeconds();
        return year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds;
    }

    /**
    * Processes an expression depending if it is a regular access or an array index access
    * @param pathSection
    * @param bindingModel
    */
    function processPathSection(pathSection : string, bindingModel : any) {
        let arrayExprSections = pathSection.split('[');
        if (arrayExprSections.length > 1) // It's an array expression, ex: userControl11.txtValues[2]
        {
            let arrayName = arrayExprSections[0];
            let index = arrayExprSections[1].slice(0, -1); // remove ending bracket
            return bindingModel[arrayName][index];
        }
        return bindingModel[pathSection];
    }

    /**
     * Gets the model which is binded to the element. To do this it assesses each part of the binding expression path
     * @param binding
     * @param numbersOfLastElemetsPathToRemove
    */
    export function getModelFromBinding(binding: any, numbersOfLastElemetsPathToRemove: number = 0) { 
        let pathSections = binding.path.split(".").slice(0, -1 * (numbersOfLastElemetsPathToRemove + 1)); //  by default remove the binded property, and removed the n-element last before of binding property.
        let model = binding.source;
        for (let i = 0; i < pathSections.length; i++) {
            let currentParent = pathSections[i];
            model = processPathSection(currentParent, model);
            if (model == undefined)
                return null;
        }
        return model;
    }
}


