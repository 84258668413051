/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {

    enum typeObserver {
        mutation,
        DOMAttrModified,
        onpropertychange
    }

    export class DOMAttributeChangeObserver {
        private Observer = null;
        private MutationObserver: any;
        private Type: typeObserver;
        private Context: any;
        private CallBack: any;
        private Element: any;

        constructor(context) {
            var that = this;
            that.MutationObserver = (<any>window).MutationObserver || (<any>window).WebKitMutationObserver || (<any>window).MozMutationObserver;
            that.Context = context;
            that.CallBack = null;
            that.Element = null;
        }

        public registerListener(element, callback) {
            var that = this;
            that.CallBack = callback;
            that.Element = element;

            if (MutationObserver) {
                that.Type = typeObserver.mutation;
                var options = {
                    subtree: false,
                    attributes: true,
                    attributeOldValue: true
                };

                that.Observer = new MutationObserver(function (mutations) {
                    mutations.forEach(function (e) { that.CallBack.call(that.Context, e); });
                });

                that.Observer.observe(that.Element, options);

            } else if (that.isDOMAttrModifiedSupported()) {
                that.Type = typeObserver.DOMAttrModified;
                console.error("The DOMAttrModified is not support, possible code  : $(that.Element).on('DOMAttrModified', function (e) { that.CallBack.call(context, e); } );");
            } else if ('onpropertychange' in document.body) {
                that.Type = typeObserver.onpropertychange;
                console.error("The onpropertychange is not support, possible code  : $(that.Element).on('propertychange',  function (e) { that.CallBack.call(context, window.event); });");
            }
        }

        public deregisterListener() {
            var that = this;
            switch (that.Type) {
                case typeObserver.mutation:
                    if (that.Observer) {
                        that.Observer.disconnect();
                        that.Observer = null;
                    }
                    break;
                case typeObserver.DOMAttrModified:
                    $(that.Element).off('DOMAttrModified', this.CallBack);
                    break;
                case typeObserver.onpropertychange:
                    break;
            }
        }

        private isDOMAttrModifiedSupported(): boolean {
            var p: any = document.createElement('p');
            var flag = false;

            if (p.addEventListener) p.addEventListener('DOMAttrModified', function () {
                flag = true
            }, false);
            else if (p.attachEvent) p.attachEvent('onDOMAttrModified', function () {
                flag = true
            });
            else return false;

            p.setAttribute('id', 'target');

            return flag;
        }
    }
}