/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Ui {
        export class KendoView extends Mobilize.Ui.View {
            name = "";

            templateString = "<div>No template</div>";

            dispose() {
                if (!this.model["IsMdiParent"]) {
                    ($(this.id).data("kendoWindow") as any).close();
                    ($(this.id).data("kendoWindow") as any).destroy();
                    //mobilize-note: lfonseca. Adding validation to preserve the overlay when other form is still on modal view. ealeman 
                    if (!this.PendingModalViews(this.model.UniqueID)) {
                        ($(".k-overlay") as any).hide();
                    }
                }
                else {
                    window.close();
                }
            }

            render() {
                this.addTemplate();
                this.bindKendo();
                this.AddCloseEvent();
                this.focus();
            }

            bindings() {
            }

            public PendingModalViews(uniqueID) {
                var ActiveView = window.app.viewManager.views;
                for (var i = 0; i != ActiveView._values.length; i++) {
                    var view = ActiveView._values[i];
                    if (view.model.UniqueID != uniqueID) {
                        var isModal = view.model.IsModalView;
                        if (isModal)
                            return true;
                    }
                }
                return false;
            }

            public invoke(command: Mobilize.Contract.Ui.ICommand) {
                if (command.name !== null && command.name !== undefined && command.name === Mobilize.Ui.Command.Commands.SendCommand) {
                    var sendCommand = command as Mobilize.Ui.Command.Send;
                    if (sendCommand) {
                        var defered = $.Deferred();
                        const callback = (data) => {
                            defered.resolve();
                            if (sendCommand.callback) {
                                sendCommand.callback(data);
                            }
                        };
                        super.invoke(new Mobilize.Ui.Command.Send(sendCommand.sender, sendCommand.action, sendCommand.args, callback));
                        return defered.promise();
                    }
                } else {
                    super.invoke(command);
                }
                return null;
            }

            private get id(): string {
                return `#${this.model.UniqueID}`;
            }

            private addTemplate() {
                const template = kendo.template(this.templateString)(this.model);
                if (this.model["IsMdiParent"]) {
                    $("#mainContent").replaceWith(template);
                } else {
                    $("#mainContent").append(template);
                    this.renderAsFloating();
                }
            }
            private bindKendo() {
                kendo.bind($(this.id), kendo.observable(this.model));
            }

            private focus() {
                $(this.id + " *[tabindex=\"1\"]").first().focus();
            }

            //Adds a close event if not added at migration time
            private AddCloseEvent() {
                const kendoInfo: any = $(this.id).data("kendoWindow");
                if (!kendoInfo) {
                    console.info("Possible initialization error. A kendoWindow was expected but it was not found for: " + this.model["Name"]);
                    return;
                }

                var self = this;

                if (!kendoInfo._events || !kendoInfo._events.close) {
                    kendoInfo.bind("close", (e) => {
                        e.preventDefault();
                        try {
                            if (event != null && (event as any).keyCode == 27)
                                return;
                        } catch (e) { }
                        setTimeout(function () {
                        if (e.userTriggered) {
                            self.requestClose();
                        }
                        }, 350);
                    });
                }
            }

            private renderAsFloating() {
                const width = $(this.id).attr("data-width");
                const height = $(this.id).attr("data-height");
                const top = $(this.id).attr("data-top");
                const left = $(this.id).attr("data-left");
                const removeControlBox = $(this.id).attr("data-removeControlBox");
                var actions = [];
                if (width && height) {
                    if (removeControlBox)
                        actions = [];
                    else
                        actions = ["Minimize", "Maximize", "Close"];

                    var top1 = Number(top);
                    var left1 = Number(left);
                    if (isNaN(top1) && isNaN(left1)) {
                        top1 = 30;
                        left1 = 0;
                        // calculate the centered rendering
                        if (Number(width) < window.innerWidth) {
                            left1 = (window.innerWidth / 2) - (Number(width) / 2);
                        }
                        if (Number(height) < window.innerHeight) {
                            top1 = (window.innerHeight / 2) - ((Number(height) + top1) / 2);
                        }
                    }

                    $(this.id).kendoWindow({ modal: this.model.IsModalView, width: width, height: height, resizable: false, actions: actions, position: { top: top1, left: left1 } });
                    if (this.model.IsModalView) {
                        let modal = $(this.id).data("kendoWindow");
                        modal.toFront();
                    }
                }
                else {
                    if (removeControlBox)
                        actions = [];
                    else
                        actions = ["Minimize", "Maximize", "Close"];
                    $(this.id).kendoWindow({ actions: actions, modal: this.model.IsModalView });
                    if (this.model.IsModalView) {
                        let modal = $(this.id).data("kendoWindow");
                        modal.toFront();
                    }
                }
                $(this.id).css("overflow", "hidden");
            }
        }
    }
}