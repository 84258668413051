/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/


module kendo.data.binders.widget {

    export class viewmodel extends Binder {
        constructor(element: Element, bindings: { [key: string]: Binding; }, options?: any) {
            super(element, bindings, options);
            var binding: any = this.bindings["viewmodel"];
            var widget: Mobilize.BaseWidget = this.element;
            widget.setViewModel(binding.get())
        }
        refresh(attribute?: string): void {
        }

    }
}


module Mobilize {
    var ui = kendo.ui, Widget = ui.Widget;
    /*
    *  This class provides an object to register an specific handler
    *  for a property or a set of properties changes.
    */
    export class PropertyHandler {
        //Reference to the parent widget
        private widget: BaseWidget;
        // Function that is called when the property changes.
        private functionName: string;
        // parameters required by the function
        private parameters: any[];
        // A timeout function that executes the registered handler.
        private triggerAction: any;

        constructor(widget_: BaseWidget, function_name: string, parameters_: any[]) {
            this.widget = widget_;
            this.functionName = function_name;
            this.parameters = parameters_;
        }

        /*
        *   Register an action when the triggerAction is undefined
        *   Notice: Only one instance should exist at a time.
        */
        public registerChange() {
            var that = this;
            if (!that.triggerAction) {
                that.triggerAction = setTimeout(function () {
                    try {
                        that.widget[that.functionName].apply(that.widget, that.parameters)
                    }
                    catch (ExpCatch) {
                        console.warn(ExpCatch);
                    }
                },100);
                that.triggerAction = undefined;
            }
        }
    }
    export class BaseWidget implements IWidget {

        public options = {
            /** the name is what it will appear as off the kendo namespace(i.e. kendo.ui.WindowsFormsApplication1_UserControl1). 
                * The jQuery plugin would be jQuery.fn.kendoUserControl1.
                */
            name: "BaseWidget",
            css: undefined,
            template: undefined,
            value: null,
            uiinitialized: false
        }

        // In order to update the UI, with changes comming from the server we are going to Queue them, in order to call
        //the refresh handlers associated to a property, just once.
        private timeoutHandlers: { [uniqueid: string]: { [id: string]: number; } } = {};

        /* Prefix used by the property handlers*/
        private _prefix = 'applyRefresh'

        /**
        * Instance that manages the log feature for this widget.
        */
        private Logger = new Mobilize.Log(true, this);
        /**
         *   This describes the set of events that are used by the widget.
         *   You should override this set on your widget definition
         *   For example if your widget has a custom event. Do something like:
         *   events = [Global.RESIZE, Global.MOVED, Global.CHANGE]
         */
        public events: string[] = []

        /**
        * The viewmodel that is binded to this widget
        */
        public ViewModel: IViewModel;
        
        /**
        * The DOM element
        */
        public element: JQuery;


        /*
        * Current BaseType
        */
        public BaseType: any = kendo.ui.Widget;
        /**
        * Flag that indicates if the current widget is initialized.
        */
        isInitialized: boolean = false;

        /*
        * Dictionary that keeps the listener functions that are
        * registered by the current widget
        */
        private fieldsSetListeners = {};

        initUI() { }

        //Kendo init method
        init(element: JQuery, options?: Object) {
          //Calls base Init
            Widget.fn.init.call(this, element, options);
        }

        /*
        * Add a listener function in case a field changes.
        */
        addFieldListener(functionName: string, parameters: any[], properties: string[]) {
            var that = this;
            try {
                if (functionName && functionName.length > 0
                    && that[functionName]
                    && typeof that[functionName] === 'function'
                    && (functionName as any).slice(0, that._prefix.length) == that._prefix) {

                    var handler = new PropertyHandler(that, functionName, parameters);
                    properties.forEach(function (property: string) {
                        that.fieldsSetListeners[that.normalizeName(property)] = handler;
                    });
                }
                else {
                    that.Logger.Print(["::addFieldListener. Failed registering the function '" + functionName], typeLOG.LOG_WARNING);
                }
            }
            catch (exception) {
                that.Logger.Print(["::addFieldListener. " + exception, exception.stack], typeLOG.LOG_WARNING);
            }
        }

        /*
        * This method initializes the Object viewmodel
        */
        setViewModel(val: IViewModel) {
            let that = this;
            //If the UI is not initialized. We initializr the grid.
            if (!that.isInitialized && val && !that.ViewModel) {
                try {
                    //Sets the ViewModel binded to the Grid to this instance.
                    that.ViewModel = val;
                    that.initUI();
                    that.setBind();
                    that.isInitialized = true;
                } catch (e) {
                    that.Logger.Print(["::setViewModel. " + e, e.stack], typeLOG.LOG_WARNING);
                }
            }
        }

        /*Bind the viewmodel changes
        * And executes the refresh to the property
        */
        setBind() {
            let that = this;
            that.ViewModel.bind('change', (event) => {
                var propertyName = that.normalizeName(event.field);
                var functionName = that._prefix + propertyName;
                if (that[functionName]) {
                    that.QueueFieldUpdate(functionName);
                }
            });
        }


        /**
         * check if the property exists in dictionary, if not then Dispatch
         */
        private isDispatchableProperty(property: string): boolean {
            var that = this,
                value = true

            if (that.fieldsSetListeners[property]) {
                that.fieldsSetListeners[property].registerChange()
                value = false
            }

            return value
        }
		

        //
        // In case a property is modified then we should execute the
        //handlers related to it, but only once. The why we queue them
        //for later.
        private QueueFieldUpdate(functionName: string) {
                //Is that model being listened?
                if (!this.timeoutHandlers[this.ViewModel.UniqueID])
                    this.timeoutHandlers[this.ViewModel.UniqueID] = {};
                //Do we have a handler already queued for this property?
                var queued = this.timeoutHandlers[this.ViewModel.UniqueID][functionName];
                if (!queued) {
                    // Let's register a timeout Id so that, we can track
                    //the handlers associated to the Field and also cancel 
                    //them if required.
                    this.timeoutHandlers[this.ViewModel.UniqueID][functionName] = setTimeout((() => {

                        if (this.isDispatchableProperty(functionName) && this[functionName])
                            //Retrieves the handlers associated with a property change
                            try {
                                this[functionName](this.element, this.ViewModel);
                            } catch (ex) {
                                console.error("Error executing change handler for the field name: " + functionName + " Error:" + ex);
                            }
                
                        //Removes the timeout handler for the property.
                        delete this.timeoutHandlers[this.ViewModel.UniqueID][functionName]
                    }).bind(this), 0);
            }
        }

        /*Normalize the property Name
                names are  case sensitive
         *      if name contains dot it replace it with _
         * 
         * samples
         * 
         *      property
         * 
         *          width               -> applyRefreshwidth(element: JQuery, vm: <name_viewmodel>ViewModel)
         *          Panel1.backcolor    -> applyRefreshPanel1_backcolor(element: JQuery, vm:<name_viewmodel>ViewModel)
         *          Font.size.Width     -> applyRefreshFont_size_Width(element: JQuery, vm: <name_viewmodel>ViewModel)
        */
        normalizeName(name: string) {
            let rname: string = "dummy";
            try {
                rname = name.replace(/[\./]/g, "_");
            } catch (exception) {
                this.Logger.Print(["::normalizeName. " + "( " + name + "  )" + exception, exception.stack], typeLOG.LOG_WARNING);
            }
            return rname;
        }
    }

}