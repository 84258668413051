/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Ui {
        export class KendoViewResolver implements Mobilize.Contract.Ui.IViewResolver {
            resolve(model: Mobilize.Contract.Core.IModel, load: (view: Mobilize.Contract.Ui.IView) => void) {
                require([model["Name"]], ViewConstructor => load(new ViewConstructor(model)));
            }
        }
    }
}