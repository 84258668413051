/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Resolvers {
        export class KendoChangeNotifier implements Mobilize.Contract.Core.IModelNotifier {
            apply(model: Mobilize.Contract.Core.IModel) {
                ((model as any) as kendo.Observable).bind("change", (event) => {
                    var ignore = (event.action && event.action === "itemchange") || (event.field && event.field.indexOf('.') > -1);
                    if (!ignore) {
                        model["__onchange"](event.field);
                    }
                });
            }
        }
    }
}