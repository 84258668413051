/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Ui.Command {
        export class DragAndDrop implements Mobilize.Contract.Ui.ICommand {
            private _sender: Mobilize.Contract.Ui.IView;
            private _form: any;
            private _controlName: string;
            private _uniqueId: string;

            constructor(data: any) {
                this._form = data.parameters.FormUID;
                this._uniqueId = data.parameters.ControlUID;
                this._controlName = data.parameters.ControlName;

            }

            get sender(): Mobilize.Contract.Ui.IView {
                return this._sender;
            }

            get form(): any {
                return this._form;
            }

            get name(): string {
                return "dragdropstart";
            }

            get UniqueID(): string {
                return this._uniqueId;
            }
            get ControlName(): string {
                return this._controlName;
            }
        }
    }
}