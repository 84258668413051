/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
* Based on plugin posted in StackOverflow
* http://stackoverflow.com/a/34387131
**************************************************************************/


module WebMap.Client {
    var binders = kendo.data["binders"] as any;
    binders.widget.monthCalendarSelectionRange = kendo.data.Binder.extend({
        init: function (element, bindings, options) {
            (<any>kendo.data.Binder.fn).init.call(this, element, bindings, options);
            var that = this;
            that.firstDate = true;
            this.element.element.bind("click",
                function (item) {
                    that.change(item, that);
                });
        },
        refresh: function () {
            var that = this;
            var binding = this.bindings["monthCalendarSelectionRange"];
            var selectionRange = binding.get();
            var cells = $(that.element.element).find('td');
            updateCell(cells, [new Date(selectionRange.Start), new Date(selectionRange.End)]);
        },
        change: function (item, that) {
            var value = this.element.value();
            var binding = this.bindings["monthCalendarSelectionRange"];
            var selectionRange = binding.get();
            var cells = $(that.element.element).find('td');
            if (that.firstDate) {
                selectionRange.set("Start", value);
            }
            else if (value < selectionRange.Start) {
                selectionRange.set("Start", value);
                selectionRange.set("End", value);
            }
            else {
                selectionRange.set("End", value);
            }
            //Highlight the selected dates
            updateCell(cells, [new Date(selectionRange.Start), new Date(selectionRange.End)]);
            that.firstDate = !that.firstDate;
        }
    });

    function updateCell(cells, selectedDates) {
        //Remove time from selectedDates to ignore it in the cell comparison.
        selectedDates[0].setHours(0, 0, 0, 0);
        selectedDates[1].setHours(0, 0, 0, 0);
        cells.each((i, cell) => {
            var cellDate = dateFromCell(cell).getTime();
            var selected = cellDate >= selectedDates[0] && cellDate <= selectedDates[1]; // isDate in Range
            if (selected) {
                $(cell).addClass('k-state-selected');
            }
            else {
                $(cell).removeClass('k-state-selected');
            }
        });
    }

    function dateFromCell(cell) {
        return new Date(convertDataValue($(cell).find('a').attr('data-value')));
    }


    //convert from 'YYYY/MM/DD', where MM = 0..11
    function convertDataValue(date) {
        var regex = /\/(\d+)\//;
        var month = +date.match(regex)[1] + 1;

        return date.replace(regex, '/' + month + '/');
    }

}