/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize {
    export namespace Ui {
        var ui = kendo.ui, Widget = ui.Widget;

        export class KendoUtils {

            static getControlHtml(control): string {
                var itemBindingExpression = KendoUtils.getBindingExpressionFromModel(control);
                var ids = KendoUtils.getIdToHtmlFromUserControl(control);
                var controlTemplate = "<div id='" + ids.identifier + "' data-role='" + ids.uid + "' data-bind='value: " + itemBindingExpression + "' class='" + ids.alias + "'></div>";
                return controlTemplate;
            }

            /**
             * Generate the Binding Expression Given a Model
             * @param {string} uniqueID The UniqueID that will be expanded and generate the Full Path
             * @returns {string} The desired Kendo binding expression
             */
            public static getBindingExpressionFromModel(model: any): string {
                var UniqueID = model.UniqueID;
                var BindingExpressionPath = KendoUtils.GenerateBindingExpression(UniqueID, true);
                var parts = BindingExpressionPath.split(Mobilize.Core.Model.separator);
                parts.pop();
                return parts.reverse().join(".");
            }

            static getRootParent(model: any): any {
                var root = model.parent();
                while (root && root.parent()) {
                    root = model.parent();
                }
                return root;
            }

            static removeDynamicControls(element: any) {
                // remove all kendo controls before creating the new ones
                for (var div of element.children()) {
                    if (div.id.indexOf("dynamicCtrl") === 0) {
                        var htmlControl = $(div);
                        if (htmlControl && htmlControl.length > 0) {
                            var widget = kendo.widgetInstance(htmlControl, kendo.ui);
                            if (widget) {
                                widget.destroy();
                            }
                            htmlControl.remove();
                        }
                    }
                }
            }

            static areAllControlsInHtml(controls: any, element: any): boolean {
                var differentElements = false;
                if (controls && controls.Count) {
                    // check that all items in the controls list exist in the element's HTML
                    for (var control of controls) {
                        var ids = KendoUtils.getIdToHtmlFromUserControl(control)
                        var htmlControl = $(element).find($("div#" + ids.identifier))
                        if (htmlControl.length === 0 || (htmlControl.length === 1 && htmlControl.attr("data-role") != ids.uid)) {
                            differentElements = true;
                        } else if (htmlControl.length > 1) {
                            // this is for the case where getHashCodeFromUniqueID generated a duplicated id
                            for (var i = 0; i < htmlControl.length; i++) {
                                if ($(htmlControl[i]).attr("data-role") === ids.uid) {
                                    differentElements = false;
                                    break;
                                }
                                else {
                                    differentElements = true;
                                }
                            }
                        }
                    }
                }
                return differentElements;
            }


            private static getIdToHtmlFromUserControl(control) {
                var uid = KendoUtils.getKendoDataRoleFromName(window.app.getTypeInfo(control["@k"]));
                var identifier = KendoUtils.getHashCodeFromUniqueID(control.UniqueID);
                var alias = control.Name ? control.Name : uid;
                return { uid, identifier, alias };
            }

            private static getKendoDataRoleFromName(name: string): string {
                // getting the control data-role from the global type information, for example:
                //      - class: SimpleWinForm.UC.ucBaseAddress
                //      - widget name: SimpleWinForm_UC_ucBaseAddress
                //      - data role (uid below): simplewinform_uc_ucbaseaddress
                var dataRole = name ? name.toLowerCase().replace(".", "_") : "";
                return dataRole;
            }

            private static getHashCodeFromUniqueID(uniqueID: string): string {
                var hash = 0, len = uniqueID.length, chr;
                if (len === 0) { return hash.toString(16); }
                for (var i = 0; i < len; ++i) {
                    chr = uniqueID.charCodeAt(i);
                    hash = ((hash << 5) - hash) + chr;
                    hash = hash & hash; // Convert to 32bit integer
                }
                // using "dynamicCtrl" to easily identify the dynamic controls in the DOM.
                var hashStr = "dynamicCtrl" + hash.toString(16);
                // replace dash '-' with 'x' to avoid issues with invalid characters.
                return hashStr.replace("-", "x");
            }

            /**
             * Generate the Binding Expression
             * @param {string} uniqueID The UniqueID that will be expanded and generate the Full Path
             * @param {boolean}  isBuildingForKendoBindingExpression true, if is Building For Kendo, otherwise false
             * @returns {string} The desired binding expression
             */
            private static GenerateBindingExpression(uniqueID: string, isBuildingForKendoBindingExpression: Boolean) :string {
                var result = "";
                //We are going to check if the uniqueID has any List element.
                var startIndex = uniqueID.indexOf(Mobilize.Core.Model.collectionItemPrefix);
                if (startIndex == -1) {
                    return KendoUtils.GetNoExpandedBindingExpression(uniqueID, isBuildingForKendoBindingExpression);
                }

                var substring = KendoUtils.GetSubStringToBeExpanded(uniqueID, startIndex);
                var obj = window.app.models.getModel(substring);
                var parentObject = KendoUtils.GetParent(obj);
                if (KendoUtils.IsKendoArray(parentObject)) {
                    if (isBuildingForKendoBindingExpression) {
                        result = parentObject.UniqueID;
                        if (result.indexOf(uniqueID) != -1) {
                            return result;
                        }

                        return KendoUtils.GetBindingExpressionWithRealName(uniqueID) +
                            Mobilize.Core.Model.separator +
                            KendoUtils.GenerateBindingExpression(result, isBuildingForKendoBindingExpression);

                    }
                    else {
                        result = KendoUtils.GetBindingExpressionWithShortUniqueID(parentObject, uniqueID, substring);
                        if (result == "") {
                            return;
                        }

                        if (result.indexOf(uniqueID) != -1) {
                            return result;
                        }

                        return KendoUtils.GenerateBindingExpression(uniqueID.replace(substring, result), false);
                    }
                }
            }

             /**
             * Generate substring to be expanded
             * @param {string} uniqueID The UniqueID that will be expanded
             * @param {number}  startIndex The starting index of the new uniqueid to be expanded
             * @returns {object} The substring
             */
            private static GetSubStringToBeExpanded(uniqueID: string, startIndex: number): any {
                //Let's expand the UniqueID
                // Example: Given uniqueID: button1#KIABC, we have to transform it to button1#KIABC#Controls#f
                //          In this case, the startIndex is 8 and endIndex will be 12, so the KIABC will be the substring
                //          and we will proceed to expand this substring
                var endIndex = uniqueID.indexOf(Mobilize.Core.Model.separator, startIndex);
                if (endIndex == -1) endIndex = uniqueID.length;
                var substring = (startIndex == 0) ? uniqueID : uniqueID.substring(startIndex, endIndex);
                return substring;
            }

            /**
             * Indicates whether an object is a KendoObservableArray
             * @param {object} obj The object to be validated
             * @returns {boolean} True, if the obj is an array, otherwise false
             */
            private static IsKendoArray(obj: any): Boolean {
                if (obj instanceof kendo.data.ObservableArray) {
                    return true;
                }

                if (obj != null && obj.length != null && obj.length > 0) {
                    return true;
                }
            }

            /**
             * Gets No expanded Binding Expression
             * @param {string} uniqueID The UniqueID that will be expanded and generate the Full Path
             * @param {boolean}  isBuildingForKendoBindingExpression true, if is Building For Kendo, otherwise false
             * @returns {string} The desired binding expression
             */
            private static GetNoExpandedBindingExpression(uniqueID:string, isBuildingForKendoBindingExpression:Boolean) : string {
                if (isBuildingForKendoBindingExpression) {
                    //Only those unique id that goes to HTML DOM should be transformed from shorten uniqueid to real uniqueid
                    return KendoUtils.GetBindingExpressionWithRealName(uniqueID);
                }
                else {
                    return uniqueID;
                }
            }

            /**
             * Gets Binding Expression with ShortUniqueID, No real name returned.
             * @param {string} uniqueID The UniqueID that will be expanded and generate the Full Path
             * @returns {string} The desired binding expression with ShortUniqueID
             */
            private static GetBindingExpressionWithShortUniqueID(parentObject, uniqueID, substring) {
                var result = "";
                for (var i = 0; i < parentObject.length; i++) {
                    var item = parentObject[i];
                    if (item != null && item.UniqueID == substring) {
                        result = i + Mobilize.Core.Model.separator + parentObject.UniqueID;
                        break;
                    }
                }
                return result;
            }

            /**
             * Gets Binding Expression with real name, this means no ShortUniqueID is returned.
             * @param {string} uniqueID The UniqueID that will be expanded and generate the Full Path
             * @returns {string} The desired binding expression with real name
             */
            private static GetBindingExpressionWithRealName(uniqueid) {
                var parts = uniqueid.split(Mobilize.Core.Model.separator).reverse();
                var partsReal = [];
                var currentContext = parts[0];
                partsReal.push(parts[0]);
                for (var i = 0; i < parts.length - 1; i++) {
                    currentContext = parts[i + 1] + Mobilize.Core.Model.separator + currentContext;
                    var current = window.app.models.getModel(currentContext);
                    var name = current["aliasName"] ? current["aliasName"] : parts[i + 1];
                    partsReal.push(name);
                }
                return partsReal.reverse().join(Mobilize.Core.Model.separator);
            }

            /**
             * Gets the parent
             * @param {object} The object
             * @returns {object} The parent
             */
            private static GetParent(obj) {
                var parentObject = undefined;
                if (obj == null) {
                    return parentObject;
                }
                
                parentObject = obj.parent();
                //If this is null, maybe I am a list. 
                //This is a case of a list that is inside another list.
                if (parentObject == null) {
                    parentObject = obj["__parent"];
                }
                
                return parentObject;
            }
        }
    }
}
