/// <reference path="../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Resolvers {
        export class ObservableBehavior implements Mobilize.Contract.Core.IClientBehavior {
            apply(model: Mobilize.Contract.Core.IModel, root: Mobilize.Contract.Core.IEntity) {
                let observable: any;
                if (model["@arr"]) {
                    observable = new kendo.data.ObservableArray([]);
                    observable.Count = model["Count"];
                    for (let prop in model) {
                        if (prop !== "sort" && prop !== "push" && prop !== "splice") {
                            observable[prop] = model[prop];
                        }
                    }
                } else {
                    // Avoid unwanted conversion to Kendo Observable of a model's "childrenToNotify" array used in WebMap's logic
                    let tempArray = model.childrenToNotify;
                    model.childrenToNotify = null;
                    observable = new kendo.data.ObservableObject(model);
                    observable["childrenToNotify"] = tempArray;
                }
                root.replace(observable);
            }

            get Order(): Mobilize.Contract.Core.Order {
                return Mobilize.Contract.Core.Order.PRE;
            }
        }
    }
}