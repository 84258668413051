/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Ui.Modals {
        import Component = Mobilize.System.Aop.Component;
        @Component("~/dist/resources/modals/dialog/MessageBox.html", "~/dist/resources/modals/dialog/MessageBox.css")
        export class MessageBox extends Mobilize.Ui.Modal {
            windowKendo: kendo.ui.Window;
            viewModal: kendo.data.ObservableObject;
            kendoQueue: Array<Object>;

            constructor() {
                super();
                var self = this;
                this.kendoQueue = new Array();
                this.viewModal = new kendo.data.ObservableObject(
                    {
                        myButtonId: -1,
                        myIconId: -1,
                        iconQuest: function () {
                            return (this.get("myIconId") === 1);
                        },
                        iconWar: function () {
                            return (this.get("myIconId") === 2);
                        },
                        iconErr: function () {
                            return (this.get("myIconId") === 3);
                        },
                        buttonOk: function () {
                            var buttonId = this.get("myButtonId");
                            return (buttonId === 0 || buttonId === 1);
                        },
                        buttonYesNo: function () {
                            var buttonId = this.get("myButtonId");
                            return (buttonId === 3 || buttonId === 4);
                        },
                        buttonRetry: function () {
                            var buttonId = this.get("myButtonId");
                            return (buttonId === 5);
                        },
                        buttonCancel: function () {
                            var buttonId = this.get("myButtonId");
                            return (buttonId === 1 || buttonId === 3 || buttonId === 5);
                        },
                        isVisible: false,
                        status: "dialog",
                        textValue: "",
                        caption: "",
                        inputRequest: false,
                        myclick: function (e) {
                            const library: Contract.Application.ILibrary = Application.Inject.Instance.resolve(Contract.Application.Constants.Library);
                            const result = library.dom.find(e.target).data("result");
                            var input = library.dom.find("#inputModal").val();
                            self.kendoQueue.shift();
                            self.reset();
                            self.invoke(new Mobilize.Ui.Command.ModalAction(result, input));
                            if (self.kendoQueue.length) {
                                // This timeout is needed by the animation closed event.
                                // If you change the value of the timeout, you must change the duration in the MessageBox.css file
                                window.setTimeout(() => {
                                    self.showModal(self.kendoQueue[0])
                                }, 200);
                            }
                        },
                        icons: ""
                    });

                this.onLoad = this.load;
            }

            private reset() {
                const library: Contract.Application.ILibrary = Application.Inject.Instance.resolve(Contract.Application.Constants.Library);
                library.dom.find("#inputModal").val("");
                this.viewModal.set("inputRequest", false);
                this.viewModal.set("status", "dialog dialog-close");
                this.viewModal.set("textValue", "");
                this.viewModal.set("caption", "");
                this.viewModal.set("input", "");
                this.viewModal.set("myIconId", -1);
                this.viewModal.set("myButtonId", -1);
            }

            private load() {
                kendo.bind("#messageBox", this.viewModal);
            }

            click(): void {
            }

            name(): string {
                return "msg";
            }

            show(args: any): any {
                this.kendoQueue.push(args);
                if (this.kendoQueue.length === 1) {
                    this.showModal(args);
                }
            }

            private showModal(args: any) {
                this.viewModal.set("input", "");
                this.viewModal.set("inputRequest", args.inputRequest);
                this.viewModal.set("caption", args.caption);
                this.viewModal.set("textValue", args.text);
                this.viewModal.set("myButtonId", args.buttons == undefined ? 0 : args.buttons);
                this.viewModal.set("myIconId", args.icons == undefined ? 0 : args.icons);
                this.viewModal.set("status", 'modal-overlay dialog dialog-open');

            }
        }
    }
}