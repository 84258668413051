module Mobilize {
    /*
    * Defines the kind of log that will be used
    */
    export enum typeLOG {
        LOG_MSG,
        LOG_ERROR,
        LOG_WARNING
    }

    /**
    * This class provides a log manager that reports errors in 
    * the browser's console.
    */
    export class Log {
        //Enable-Disable the log 
        private EnableLog: boolean;
        //Reference to the widget 
        private Widget: BaseWidget;

        constructor(showLog: boolean, widget: BaseWidget) {
            this.EnableLog = showLog;
            this.Widget = widget;
        }

        /**
        * Generate a id  with name of the widget and UniqueID of the viewmodel
        * unknown -> viewmodel is undefined
        * undefined -> UniqueID is undefined but viewmodel is not undefined
        * @returns String ID   WG:<anem>,VM:<UniqueID>
        */
        public generateIDLog(): string {
            var that = this,
                strId = "",
                options = that.Widget.options,
                vm = that.Widget.ViewModel;

            strId = 'WG:' + options.name + ",ID:" + (vm ? (vm.UniqueID ? vm.UniqueID : "unknown") : "undefined");

            return strId;
        }



        // Prints a log message
        public Print(msg: any[], typelog: typeLOG = typeLOG.LOG_MSG) {
            if (this.EnableLog) {
                var that = this,
                    strId = that.generateIDLog(),
                    functionlog = 'log';

                msg.unshift(strId);
                msg.push(that);

                switch (typelog) {
                    case typeLOG.LOG_ERROR:
                        //functionlog = 'error';
                        functionlog = 'warn';
                        break;
                    case typeLOG.LOG_WARNING:
                        functionlog = 'warn';
                        break;
                }
                console[functionlog].apply(console, msg);
            }
        }


    }
}