/// <reference path="references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export class App extends Application.App {
        constructor() {
            super();
            this.disableLoading = false;
            this.onRegister = this.kendoRegister;
            this.onResolver = this.kendoResolver;
            this.onViewHandler = this.viewHandler;
            this.onCommandRegister = this.commandRegister;
            this.onInvoker = this.invokerRegister;
        }

        public disableLoading: boolean;
        public  mouseDragEvent :any 

        kendoRegister(inject: Contract.Application.IInject) {
            inject.register(Contract.Application.Constants.ViewResolver, Kendo.Ui.KendoViewResolver, Contract.Application.Resolve.Singleton);
            inject.register(Contract.Application.Constants.ModelFactory, Kendo.Entity.ModelFactory, Contract.Application.Resolve.Singleton);
            inject.register(Contract.Application.Constants.ModelResolver, Kendo.Resolvers.KendoModelResolver, Contract.Application.Resolve.Singleton);
        }

        kendoResolver(modelResolver: Contract.Core.IModelResolver) {
            modelResolver.replaceBehavior("PointerBehavior", new Resolvers.KendoPointerBehavior());
            modelResolver.registerBehavior(new Resolvers.ObservableBehavior());
            modelResolver.registerNotifier(new Resolvers.KendoChangeNotifier());
        }

        viewHandler(viewManager: Contract.Ui.IViewManager) {
            viewManager.registerModal(new Ui.Modals.LoadingModal());
            viewManager.registerModal(new Ui.Modals.AlertModal());
            viewManager.registerModal(new Ui.Modals.ExpiredSession());
            viewManager.registerModal(new Ui.Modals.MessageBox());
            viewManager.registerModal(new Ui.Modals.InputBox());
        }

        invokerRegister(invoker: Mobilize.Ui.Invoker) {
            var dragReceiver = new DragAndDropRegister();
            dragReceiver.registerCommand("dragdropstart", command =>
                dragReceiver.dragHandler(command as Ui.Command.DragAndDrop));
            invoker.register(dragReceiver);
        }

        commandRegister(factory: Mobilize.Contract.Ui.ICommandFactory) {
            factory.register("dragdropstart", Ui.Command.DragAndDrop);
        }

        
    }
}