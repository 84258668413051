/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Ui.Modals {
        import Component = Mobilize.System.Aop.Component;
        @Component("~/dist/resources/modals/loading/dotjump.html", "~/dist/resources/modals/loading/dotjump.css")
        export class LoadingModal extends Mobilize.Ui.Modal {
            private viewModal: kendo.data.ObservableObject;
            private timer: any;
            private blocked: any;

            constructor() {
                super();
                this.viewModal = new kendo.data.ObservableObject({ isVisible: false });
                this.onLoad = this.load;
                this.timer = null;
            }

            name(): string {
                return "loading";
            }

            show(args: any) {
                //debugger
                if (this.blocked !== true || args.blocked === false) {
                    if (window.app && window.app.disableLoading && window.app.disableLoading == true) {
                        args.show = false;
                    }
                    if (this.timer) {
                        clearTimeout(this.timer);
                    }
                    //this.viewModal.set("isVisible", args.show);
                    // wait before showing the loading modal, quick actions shouldn't show it.
                    if (args.show) {
                        if (args.blocked === true) {
                            this.viewModal.set("isVisible", args.show);
                        } else {
                            this.timer = setTimeout(() => {
                                this.viewModal.set("isVisible", args.show);
                            }, 300); //1500 //mobilize-note: jcruz. Changing the original value of 1500 ms to 800 ms trying to show the busy loader in most of the request cases 
                        }
                    } else {
                        this.viewModal.set("isVisible", args.show);
                    }
                }
                if (args.blocked !== undefined) {
                    this.blocked = args.blocked;
                }
                
            }

            private load() {
                kendo.bind("#loading", this.viewModal);
            }
        }
    }
}