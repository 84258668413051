/// <reference path="../../references.ts" />
/*************************************************************************
*
* MOBILIZE CONFIDENTIAL
* _______________________________________________________________________
*
*  Mobilize Company
*  All Rights Reserved.
*
* NOTICE: All helper classes are provided for customer use only;
* all other use is prohibited without prior written consent from Mobilize.Net.
* no warranty express or implied;
* use at own risk.
**************************************************************************/
module Mobilize.WebMap.Kendo {
    export namespace Ui.Modals {
        import Component = Mobilize.System.Aop.Component;

        @Component("~/dist/resources/modals/alert/classic.html", "~/dist/resources/modals/alert/classic.css")
        export class AlertModal extends Mobilize.Ui.Modal {
            private viewModal: kendo.data.ObservableObject;
            private isLoaded: boolean;
            constructor() {
                super();
                this.viewModal = new kendo.data.ObservableObject(
                    {
                        caption: "",
                        message: "",
                        info: "",
                        type: "",
                        tooltip: "",
                        notif: "",
                        isShowInfo: false,
                        showInfo: () => {
                            this.viewModal.set("isShowInfo", !this.viewModal.get("isShowInfo"));
                        }
                    }
                );
                this.onLoad = this.load;
                this.isLoaded = false;
            }

            name(): string {
                return "alert";
            }

            show(args: any) {
                this.viewModal.set("caption", args.caption || "Alert");
                this.viewModal.set("message", args.message || "Something happends!!");
                this.viewModal.set("info", args.info || "");
                this.viewModal.set("type", args.type || "error");
                this.viewModal.set("tooltip", args.tooltip || "info");
                this.viewModal.set("notif", `notif ${args.type || "error"}`);
                this.viewModal.set("isShowInfo", false);

                const library: Contract.Application.ILibrary = Application.Inject.Instance.resolve(Contract.Application.Constants.Library);
                const result = <any>library.dom.find("#alert");
                result.fadeIn("slow");
                setTimeout(() => {
                    result.fadeOut(1000);
                    this.viewModal.set("isShowInfo", false);
                }, 5000);
            }

            private load() {
                this.isLoaded = true;
                kendo.bind("#alert", this.viewModal);
            }
        }
    }
}